import BellAlertIcon from "./icons/BellAlertIcon";
import CreditCardIcon from "./icons/CreditCardIcon";
import ShareIcon from "./icons/ShareIcon";
import SwatchIcon from "./icons/SwatchIcon";
import UsersIcon from "./icons/UsersIcon";
import OnboardingSteps from "./OnboardingSteps";
import Link from "next/link";
import { useAppRouter } from "@/src/useAppRouter";
import SimpleDate from "@/lib/SimpleDate";
import { pluralCount } from "@/lib/string";
import classNames from "classnames";
import QuestionMarkCircleIcon from "./icons/QuestionMarkCircleIcon";
import DocumentTextIcon from "./icons/DocumentTextIcon";
import useCurrentOrg from "@/src/useCurrentOrg";
import { useEffect, useState } from "react";
import rpc from "@/src/rpc";
import WarningIcon from "./icons/WarningIcon";
import useRpc from "@/src/useRpc";
import CalendarIcon from "./icons/CalendarIcon";


export default function LeftNav ({}) {

  const router = useAppRouter()
  const activeMenu = router.query.menu

  const org  = useCurrentOrg()
  const [socialReconnectNeeded, setSocialReconnectNeeded] = useState(false)

  const [billing] = useRpc('billing.info')

  const today = new SimpleDate()
  const trialEndDate = billing && new SimpleDate(billing.trialEndDate)

  const daysLeftInTrial = trialEndDate ? today.daysBetween(trialEndDate) : 0

  // NOTE: Using `preciseStatus` here because we don't want to show the trial
  // days remaining if the org has added a credit card (they will convert)
  const showTrialDaysRemaining = org?.preciseStatus === 'trialing'

  // Only show the Topics menu to a few orgs for now
  const orgAllowed = org?.demo || [ 6462, 7140, 7146, 7231, 7126, 7245, 7248 ].includes(org?.id)
  const showContentPlan = org && !org.aiEnabled && orgAllowed

  // Check to see if there are any social accounts that need to be reconnected
  useEffect(() => {
    checkSocialStatus()

    async function checkSocialStatus () {
      const status = await rpc('social.status')
      if (!status) return
      const expiringSoon = status.destinations.find(dest => {
        return dest.daysUntilDisconnect < 14
      })
      if (expiringSoon) setSocialReconnectNeeded(true)

      const needsReconnectForMetrics = status.destinations.find(dest => {
        return dest.needsReconnectForMetrics
      })
      if (needsReconnectForMetrics && org?.metricsEnabled) {
        setSocialReconnectNeeded(true)
      }
    }
  }, [])


  return <>
    <div className="flex-col bg-white full-height overflow-auto-y" style={{borderRight: '1px solid #ddd', minWidth: 300}}>

      { !!org &&
        <>
          <div className="setup-progress">
            <div className="relative">
              <OnboardingSteps org={org}/>
            </div>
          </div>
        </>
      }

      { org?.showBilling &&
        <Link href={router.path('subscription')}>
          <a className={classNames("flex-col menu-item relative", {active: activeMenu === 'subscription'})}>
            <div className="flex-row spread">
              <div className="flex-row gap-3">
                <span className="menu-icon flex"><CreditCardIcon/></span>
                <span>
                  Billing
                </span>
              </div>
              <div>
                { billing?.lastInvoice?.status === 'failed' ?
                  <span className="tiny pt-1 red">
                    Payment failed!
                  </span>
                : showTrialDaysRemaining &&
                  <span className={classNames("tiny pt-1 dark-gray", {red: daysLeftInTrial <= 3})}>
                    { daysLeftInTrial <= 0 ?
                      <>Last day of</>
                    :
                      <>{ pluralCount(daysLeftInTrial, 'day')} left in</>
                    } free trial
                  </span>
                }
              </div>
            </div>
            <div className="menu-subtitle">
              Subscription and Payment Info
            </div>
          </a>
        </Link>
      }

      <Link href={router.path('brand')}>
        <a className={classNames("flex-col menu-item relative", {active: activeMenu === 'brand'})}>
          <div className="flex-row gap-3">
            <span className="menu-icon flex"><SwatchIcon/></span>
            Branding
          </div>
          <div className="menu-subtitle">
            Your Logos and Colors
          </div>
        </a>
      </Link>

      <Link href={router.path('captions')}>
        <a className={classNames("flex-col menu-item relative", {active: activeMenu === 'captions'})}>
          <div className="flex-row gap-3">
            <span className="menu-icon flex"><DocumentTextIcon/></span>
            <span className="flex-row gap-2">
              Captions
            </span>
            </div>
          <div className="menu-subtitle">
            Hashtags and Contact Info
          </div>
        </a>
      </Link>

      { showContentPlan &&
        <Link href={router.path('content')}>
          <a className={classNames("flex-col menu-item relative", {active: activeMenu === 'content'})}>
            <div className="flex-row gap-3">
              <span className="menu-icon flex"><CalendarIcon/></span>
              <span className="flex-row gap-2">
                Content Plan
              </span>
              </div>
            <div className="menu-subtitle">
              Topics and Frequency
            </div>
          </a>
        </Link>
      }

      <Link href={router.path('help')}>
        <a className={classNames("flex-col menu-item relative", {active: activeMenu === 'help'})}>
          <div className="flex-row gap-3">
            <span className="menu-icon flex"><QuestionMarkCircleIcon/></span>
            Help
          </div>
          <div className="menu-subtitle">
            Ask Questions or Report Problems
          </div>
        </a>
      </Link>

      <Link href={router.path('notifications')}>
        <a className={classNames("flex-col menu-item relative", {active: activeMenu === 'notifications'})}>
          <div className="flex-row gap-3">
            <span className="menu-icon flex"><BellAlertIcon/></span>
            Notifications
          </div>
          <div className="menu-subtitle">
            Email Preferences
          </div>
        </a>
      </Link>

      <Link href={router.path('social')}>
        <a className={classNames("flex-col menu-item relative", {active: activeMenu === 'social'})}>
          <div className="flex-row gap-2 spread">
            <div className="flex-row gap-3">
              <span className="menu-icon flex"><ShareIcon/></span>
              <div className="flex-row gap-3">
                Publishing
              </div>
            </div>
            { (socialReconnectNeeded || (org && !org?.autoPublish)) &&
              <div className="text-xs">
                { socialReconnectNeeded ?
                  <div className="red flex-row gap-1 flex-align-center">
                    <WarningIcon/>
                    <span> Reconnect Needed </span>
                  </div>
                :
                  <span className="red">(Auto-Publish is Off)</span>
                }
              </div>
            }
          </div>
          <div className="menu-subtitle">
            Social Accounts and Scheduling
          </div>
        </a>
      </Link>

      <Link href={router.path('users')}>
        <a className={classNames("flex-col menu-item relative", {active: activeMenu === 'users'})}>
          <div className="flex-row gap-3">
            <span className="menu-icon flex"><UsersIcon/></span>
            Team
            </div>
          <div className="menu-subtitle">
            Co-Workers and Collaborators
          </div>
        </a>
      </Link>

    </div>


    <style jsx>{`
      hr {
        margin: 0.5rem 0;
      }
      .setup-progress {
        background-color: #dcdbff;
      }
      @media (min-width: 885px) {
        .setup-progress {
          margin-right: -1px;
          border-right: 1px solid #66669911;
        }
      }
      .menu-item, .menu-icon, .menu-subtitle {
        transition: all 250ms;
      }
      .menu-item {
        display: block;
        border-bottom: 1px solid #eee;
        color: #666;
        position: relative;
        font-weight: 500;
        padding: 0.5rem 1rem;
      }
      .menu-item.active,
      .menu-item:hover {
        color: #333;
        background-color: #e1e1ff88;
        background-color: #c7ecff77;
        border-color: #c7ecff33;
      }
      .menu-item.active {
        z-index: 1;
        background-color: #e1e1ff;
        background-color: #c7ecff;
        cursor: default !important;
      }
      .menu-icon {
        width: 24px;
        padding-top: 2px;
        color: #aaa;
      }
      .menu-item.active .menu-icon,
      .menu-item:hover .menu-icon {
        color: #666;
      }
      .menu-subtitle {
        font-size: 0.8rem;
        font-weight: normal;
        color: #999;
        margin-left: 2.25rem;
      }
      .menu-item.active .menu-subtitle,
      .menu-item:hover .menu-subtitle {
        color: #666;
      }

    `}</style>
  </>
}
